/**
 * @description i18n en
 * @author wangfupeng
 */

export default {
  editor: {
    more: 'More',
    justify: 'Justify',
    indent: 'Indent',
    image: 'Image',
  },
}
